import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    EMPTY,
    from,
    Observable,
} from 'rxjs';
import {
    concatMap,
    expand,
    reduce,
} from 'rxjs/operators';

import { Collection } from '~/app/shared/types/collection.type';
import { ContactGroup } from '~/app/domains/contacts/types/contact-group.type';

@Injectable({
    providedIn: 'root',
})
export class ContactGroupsService {
    constructor(private http: HttpClient) {}

    getAll(): Observable<Readonly<Collection<ContactGroup>>> {
        let valuesLength = 0;

        return this.getGroups().pipe(
            expand((result) => {
                valuesLength += result.values.length;
                return valuesLength >= result.totalNumber ? EMPTY : this.getGroups(result.values[result.values.length - 1]?.id || 0);
            }),
            reduce((acc: Readonly<Collection<ContactGroup>>, val: Readonly<Collection<ContactGroup>>) => ({
                ...acc,
                values: [...acc.values, ...val.values],
            }), {
                totalNumber: 0, startAt: 0, size: 0, values: [],
            }),
        );
    }

    getGroups(startAt = 0, size = 50) {
        return this.http.get<Readonly<Collection<ContactGroup>>>('/contact-groups',
            { params: {
                size, startAt,
            } });
    }

    getGroupById(id: number) {
        return this.http.get<Readonly<ContactGroup>>(`/contact-groups/${id}`);
    }

    create(name: string) {
        return this.http.post<Readonly<ContactGroup>>('/contact-groups', { name });
    }

    update(id: number, name: string) {
        return this.http.put<Readonly<ContactGroup>>(`/contact-groups/${id}`, { name });
    }

    deleteGroupById(id: number) {
        return this.http.delete(`/contact-groups/${id}`);
    }

    addContactsByGroupId(id: number, contactsIds: number[]) {
        return this.http.post(`/contact-groups/${id}/contacts`, contactsIds);
    }

    addContactsByGroupsIds(ids: number[], contactsIds: number[]) {
        return from(ids).pipe(
            concatMap((id) => this.addContactsByGroupId(id, contactsIds)),
        );
    }

    deleteContactsByGroupId(id: number, contactsIds: number[]) {
        return this.http.delete(`/contact-groups/${id}/contacts`, { body: contactsIds });
    }

    deleteContactsByGroupIds(groupIds: number[], contactsIds: number[]) {
        return from(groupIds).pipe(
            concatMap((groupId) => this.deleteContactsByGroupId(groupId, contactsIds)),
        );
    }
}
